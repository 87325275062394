<template>
    <div class="_volWrap">
        <div class="_phoneMenu" v-if="!seen">
            <div class="_li" v-for="(item, index) in phoneMenu" :key="index" :class="{ p_active: p_current == index }"
                @click="$router.push({ path: item.path })">
                {{ item.text }}
            </div>

        </div>

        <div class="_vol_auto">

            <div class="_first">
                <h4></h4>

                <div class="_list">
                    <div class="_li" v-for="(item, index) in list" :key="index" @click="goDetail(item)">
                        <div class="_left">
                            <img :src="$IMG_URL + item.cover" alt="">
                        </div>

                        <div class="_right">
                            <div class="_h4">{{ item.title }}</div>
                            <div class="_p">{{ item.description }}</div>
                            <div class="_time">
                                <span>{{ item.release_date }}</span>
                                <span class="_shu"></span>
                                <img src="@/assets/images/yj.png" alt="">
                                <span>{{ item.hits }}</span>
                            </div>

                            <div class="_xq">查看详情</div>
                        </div>
                    </div>
                </div>

                <div class="_page">
                    <pagination v-show="total > 0" :total="total" :page.sync="queryParams.page"
                        :limit.sync="queryParams.page_num" @pagination="getList" />
                </div>
            </div>
        </div>


        <div class="_two" id="zyzfc">
            <div class="_twoAuto">
                <h4></h4>
                <div class="_list">
                    <div class="_li" v-for="(item, index) in list1" :key="index">

                        <div class="_img">
                            <img :src="$IMG_URL + item.cover" alt="">
                        </div>
                        <div class="_jies">
                            <div class="_h4">{{ item.title }}</div>
                            <div class="_p">{{ item.age }}岁</div>
                        </div>
                    </div>
                </div>

                <div class="_gd" @click="lookMore" style="cursor: pointer;">查看更多志愿者</div>
            </div>

        </div>


        <div class="_three" @click="goBm">


        </div>



        <fixIcon />
        <coment ref="comentRef"></coment>
        <mainFooter ref="mainFooterRef"></mainFooter>
    </div>
</template>

<script>
import { getDataById, hits } from '../../api/getData'
export default {
    data() {
        return {
            p_current: 2,
            total_fc: 0,
            page: 1,
            list: [],
            list1: [],
            total: 0,
            queryParams: {
                page: 1,
                page_num: 3,
            },
            seen: true,
            phoneMenu: [
                {
                    text: "参观服务",
                    path: "/exhibit"
                }, {
                    text: "精品文创",
                    path: "/boutique"
                }, {
                    text: "志愿者之家",
                    path: "/volHome"
                }
            ]
        }
    },
    async mounted() {
        this.menuSeen()
        await this.getList()

        await this.getList1()


        let title = this.$route.query.tit
        if (title == '志愿者风采') {
            this.$scrollToSection('zyzfc')
        } else {
            this.$scrollTop()

        }
    },
    methods: {
        goBm() {
            this.$alert('即将开放报名通道', '提示', {
                confirmButtonText: '确定',
                callback: action => {
                    
                }
            });

        },
        menuSeen() {
            // 屏幕尺寸
            let screenHeight = document.body.clientWidth;
            if (screenHeight > 415) {
                this.seen = true;
            } else {
                this.seen = false;
            }
        },
        async getList() {
            const params = {
                cate_id: 20,
                page: this.queryParams.page,
                page_num: this.queryParams.page_num,
                keywords: "",
                type_id: "",
                times: "",
            }
            await getDataById(params).then(res => {
                console.log(res)
                this.list = res.data.data
                this.total = res.data.total
            })
        },
        async getList1() {
            const params = {
                cate_id: 21,
                page: this.page,
                page_num: 5,
                keywords: "",
                type_id: "",
                times: "",
            }
            await getDataById(params).then(res => {
                console.log(res)
                this.list1 = [...this.list1, ...res.data.data]
                this.total_fc = res.data.total

            })
        },
        lookMore() {
            this.page += 1
            if (this.list1.length >= this.total_fc) {
                return this.$message({
                    customClass: "myAlert2",
                    type: "warning",
                    message: "没有更多了"
                })
            } else {
                this.getList1()

            }

        },
        goDetail(item) {
            let url = window.origin
            if (item.link != null && item.link != '') {
                const params = {
                    id: item.id
                }
                hits(params)
                window.open(item.link)
            } else {
                window.open(`${url}/studyDetail?tit=志愿者之家&id=${item.id}`)

            }

        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/common/mixin.scss";

._page
{
    text-align: center;
}

@mixin tit($url)
{
    width: 299px;
    height: 52px;
    background-image: url($url);
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

._volWrap
{
    width: 100%;
    background-color: #F8F5EE;
    overflow: hidden;
}

._vol_auto
{
    width: 85%;
    margin: 0 auto;
    margin-top: 200px;

    h4
    {
        @include tit("../../assets/images/h4-12.png");
        margin: 0 auto;
        margin-bottom: 60px;
    }
}


._first
{
    ._list
    {
        ._li
        {
            display: flex;
            margin-bottom: 60px;
            cursor: pointer;

            ._left
            {
                width: 20.4%;
                height: 185px;
                overflow: hidden;

                img
                {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    transition: all .5s;
                }

                img:hover
                {
                    transform: scale(1.1);
                }
            }

            ._right
            {
                margin-left: 26px;
                width: 78%;

                ._h4
                {
                    font-size: 28px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }

                ._p
                {
                    color: #333;
                    margin: 12px 0;
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    /* 设置最大显示行数 */
                    -webkit-box-orient: vertical;
                    text-overflow: ellipsis;
                }

                ._time
                {
                    display: flex;
                    align-items: center;
                    color: #666666;

                    ._shu
                    {
                        display: inline-block;
                        width: 1px;
                        height: 12px;
                        background-color: #ccc;
                        margin: 0 20px;
                    }
                }

                ._xq
                {

                    @include tit("../../assets/images/lingx.png");

                    width: 132px;
                    height: 40px;
                    text-align: center;
                    line-height: 40px;
                    color: white;
                    float: right;
                    margin-top: 20px;

                }
            }
        }
    }



}


._two
{
    @include tit("../../assets/images/t_bg.png");
    width: 100%;
    height: 704px;
    margin-top: 50px;
    overflow: hidden;

    ._twoAuto
    {
        width: 85%;
        margin: 0 auto;

        h4
        {
            @include tit("../../assets/images/h4-13.png");
            margin: 0 auto;
            margin-bottom: 60px;
            margin-top: 84px;
        }
    }



    ._list
    {
        display: flex;
        flex-wrap: wrap;

        ._li
        {
            // width: 250px;
            width: 15.4%;
            height: 250px;
            border-radius: 50%;

            overflow: hidden;
            position: relative;
            margin-right: 5.7%;


            ._img
            {
                width: 100%;
                height: 100%;
                overflow: hidden;

                img
                {
                    width: 100%;
                    height: 100%;
                    transition: all .5s;
                }

                img:hover
                {
                    transform: scale(1.1);
                }
            }

            ._jies
            {
                width: 100%;
                height: 69px;
                background: #8A2415;
                border-radius: 50%;
                opacity: 0.8;
                position: absolute;
                bottom: 0;
                text-align: center;

                ._h4
                {
                    color: white;
                    font-weight: bold;
                    font-size: 18px;
                    margin-top: 10px;
                }

                ._p
                {
                    color: white;
                    margin-top: 2px;
                }

            }

            &:nth-child(5n)
            {
                margin-right: 0;
            }
        }
    }

    ._gd
    {
        @include tit("../../assets/images/gd.png");
        width: 192px;
        height: 40px;
        text-align: center;
        line-height: 40px;
        color: #8A2415;
        margin: 0 auto;
        margin-top: 115px;


    }
}


._three
{
    @include tit("../../assets/images/bm.png");
    width: 100%;
    height: 338px;
    cursor: pointer;

}


._phoneMenu
{
    // @include respondTo('phone'){

    // }

    display: flex;
    width: 100%;
    margin-top: 60px;
    background-color: #3D4044;
    height: 50px;
    justify-content: space-around;
    align-items: center;
    color: white;

    .p_active
    {
        color: #F9DAA0;
    }
}


._vol_auto
{
    @include respondTo('phone')
    {
        width: 95%;
        margin-top: 20px;

        ._first
        {
            >h4
            {
                background-image: url("../../assets/images/phone/zyzhd.png");

                width: 173px;
                height: 30px;
                margin-bottom: 20px;
                margin-top: 20px;
            }

            ._list
            {
                ._li
                {
                    margin-bottom: 36px;

                    ._left
                    {
                        width: 40%;
                        height: 74px;

                    }

                    ._right
                    {
                        width: 50%;
                        margin-left: 20px;

                        ._h4
                        {
                            font-size: 18px;
                        }

                        ._p
                        {
                            -webkit-line-clamp: 1;
                            margin: 5px 0;
                        }

                        ._xq
                        {
                            display: none;
                        }
                    }
                }
            }
        }


    }
}


._two
{
    @include respondTo('phone')
    {
        min-height: 450px;
        height: auto;

        ._twoAuto
        {
            width: 95%;

            >h4
            {
                background-image: url("../../assets/images/phone/zyzcftit.png");

                width: 173px;
                height: 30px;
                margin-bottom: 32px;
                margin-top: 20px;
            }

            ._list
            {
                ._li
                {
                    width: 32%;
                    height: 110px;
                    margin-right: 2%;
                    margin-bottom: 14px;

                    ._jies
                    {
                        height: 50px;

                        ._h4
                        {
                            font-size: 15px;
                            margin-top: 5px;
                        }

                        ._p
                        {
                            font-size: 14px;
                        }
                    }
                }

                ._li:nth-child(3n)
                {
                    margin-right: 0;
                }
            }

            ._gd
            {
                margin-top: 50px;
            }
        }
    }

}


._three
{
    @include respondTo('phone')
    {
        background-image: url("../../assets/images/phone/bm.png");
        width: 100%;
        height: 175px;
        margin-top: 30px;
        margin-bottom: 30px;
    }
}
</style>


<style>
@media (min-width:320px) and (max-width:480px) {
    .myAlert2 {
        top: 20% !important;
    }
}
</style>
